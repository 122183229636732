import React, { useEffect } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const organicUrl = process.env.QUESTION_URL_INTEGRATED_FORM
const assetVersion = "?v=2203311700"

const LpCm2022WinterPage = () => {
  useEffect(() => {
    window.location.href = "/"
  })

  return (
      <Layout
          sectionClasses={"lp_cm2022winter"}
          dataNamespace={"lp_cm2022winter"}
          quizUrl={organicUrl}
          isHeadLogo={false}
          isHeader={false}
          isShowFooter={true}
      >
        <SEO
            title="CMキャンペーン実施中"
            image={
              "https://hotaru-personalized.com/images/lp/cm-2022-winter/pc/ogp.jpg"
            }
            description="CM放映キャンペーン実施中！#HOATRUで人生変わる のハッシュタグで2022年のなりたい自分像を投稿しよう！1月31日まで！"
        />
        <div className="lp-header">
          <a className="logo" href="/">
            <img
                src={"/images/lp/cm-2022-winter/sp/logo.svg" + assetVersion}
                alt=""
            />
          </a>
        </div>
        <section className="fv">
          <div className="fv-box">
            <picture className="fv-image">
              <source
                  media="(max-width: 428px)"
                  srcSet={"/images/lp/cm-2022-winter/sp/fv-bg.jpg" + assetVersion}
              />
              <img
                  src={"/images/lp/cm-2022-winter/pc/fv-bg.jpg" + assetVersion}
                  alt=""
              />
            </picture>
          </div>
        </section>
        <section className="cm-movie">
          <div className="cm-inner">
            <div className="cm-logo">
              <img
                  src={
                      "/images/lp/cm-2022-winter/pc/fv-movie-inner.png" + assetVersion
                  }
                  alt=""
              />
            </div>
            <div className="cm-video">
              <video
                  playsinline
                  controls
                  poster={"/images/lp/cm-2022-winter/sp/poster.jpg" + assetVersion}
              >
                <source
                    src={
                        "/images/lp/cm-2022-winter/pc/cm_2022_winter_hotaru_30.mp4" +
                        assetVersion
                    }
                    type="video/mp4"
                />
              </video>
              <div className="cm-text">TV CM[30秒ver]</div>
            </div>
          </div>
        </section>
        <section className="concept-1">
          <div className="concept-inner">
            <picture>
              <source
                  media="(max-width: 428px)"
                  srcSet={"/images/lp/cm-2022-winter/sp/concept-1.jpg" + assetVersion}
              />
              <img
                  src={
                      "/images/lp/cm-2022-winter/pc/concept1-inner.png" + assetVersion
                  }
                  alt=""
              />
            </picture>
          </div>
        </section>
        <section className="concept-2">
          <div className="concept-inner">
            <picture>
              <source
                  media="(max-width: 428px)"
                  srcSet={
                      "/images/lp/cm-2022-winter/sp/concept2_1.jpg" + assetVersion
                  }
              />
              <img
                  src={
                      "/images/lp/cm-2022-winter/pc/concept2-inner.png" + assetVersion
                  }
                  alt=""
              />
            </picture>
            <picture>
              <source
                  media="(max-width: 428px)"
                  srcSet={
                      "/images/lp/cm-2022-winter/sp/concept2_2.jpg" + assetVersion
                  }
              />
              <img src="" alt=""/>
            </picture>
          </div>
        </section>
        <section className="message">
          <div className="message-inner">
            <img
                src={"/images/lp/cm-2022-winter/sp/message.jpg" + assetVersion}
                alt=""
            />
          </div>
        </section>
        <section className="campaign" id="campaign">
          <div className="campaign-inner">
            <div className="campaign-title js-smScrollFire js-smScrollFire--fadeUp">
              <img
                  src={
                      "/images/lp/cm-2022-winter/sp/campaign-title.png" + assetVersion
                  }
                  alt=""
              />
            </div>
            <div
                className="campaign-1 js-smScrollFire js-smScrollFire--fadeUp"
                id="campaign-1"
            >
              <div className="campaign-1-img">
                <img
                    src={"/images/lp/cm-2022-winter/sp/campaign1.png" + assetVersion}
                    alt=""
                />
              </div>
            </div>
            <div
                className="campaign-2 js-smScrollFire js-smScrollFire--fadeUp"
                id="campaign-2"
            >
              <div className="campaign-2-img">
                <img
                    src={"/images/lp/cm-2022-winter/sp/campaign2.png" + assetVersion}
                    alt=""
                />
              </div>
            </div>
            <div
                className="campaign-3 js-smScrollFire js-smScrollFire--fadeUp"
                id="campaign-3"
            >
              <div className="campaign-3-img">
                <img
                    src={
                        "/images/lp/cm-2022-winter/sp/campaign3_end.jpg" + assetVersion
                    }
                    alt=""
                />
              </div>
              <ul className="campaign-note">
                <li>
                  TwitterまたはInstagramのDMにて応募者様に直接ご連絡することにより、当選者の発表に代えさせていただきます。
                </li>
                <li>※抽選結果のご連絡は2022年2月上旬(仮)を予定しております。</li>
                <li>
                  詳しくは<a href="/news/310">こちら</a>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section className="movie">
          <div className="movie-inner">
            <div className="movie-title">
              <img
                  src={"/images/lp/cm-2022-winter/sp/movie-title.png" + assetVersion}
                  alt=""
              />
            </div>
            <div className="movie-list">
              <div className="movie-item">
                <video
                    playsinline
                    controls
                    poster={"/images/lp/cm-2022-winter/sp/poster.jpg" + assetVersion}
                >
                  <source
                      src={
                          "/images/lp/cm-2022-winter/pc/cm_2022_winter_hotaru_15.mp4" +
                          assetVersion
                      }
                      type="video/mp4"
                  />
                </video>
                <div className="movie-text">TV CM[15秒ver]</div>
              </div>
              <div className="movie-item">
                <div className="movie-item-youtube">
                  <iframe
                      className="cm-youtube"
                      width="560"
                      height="315"
                      src="https://www.youtube.com/embed/nMc9cyG-xKE?controls=0"
                      frameborder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen=""
                  ></iframe>
                </div>
                <div className="movie-text">メイキング映像(3分14秒)</div>
              </div>
            </div>
          </div>
        </section>
        <section className="brand">
          <div className="brand-inner">
            <img
                src={"/images/lp/cm-2022-winter/sp/brand.jpg" + assetVersion}
                alt=""
            />
            <a className="brand-btn" href="https://medulla.co.jp/" target="_blank">
              <img
                  src={"/images/lp/cm-2022-winter/sp/brand-btn.png" + assetVersion}
                  alt=""
              />
            </a>
          </div>
        </section>
      </Layout>
  )
}

export default LpCm2022WinterPage

function copyTextToClipboard(text) {
  navigator.clipboard.writeText(text).then(function() {
    alert("ハッシュタグをコピーしました。")
  })
}
